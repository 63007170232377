import { createTheme, ThemeProvider } from '@mui/material/styles';
import { enUS, frFR } from '@mui/material/locale';
import { enUS as gridEnUS, frFR as gridFrFR } from '@mui/x-data-grid-premium/locales';
import { enUS as pickersEnUS, frFR as pickersFrFR } from '@mui/x-date-pickers-pro/locales';
import { useMemo, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';

import ApplicationContext from './ApplicationContext.js';
import { LANGUAGE_EN, LANGUAGE_FR, useInitializedI18n } from '../../services/i18n/index.js';
import useTenantConfiguration from '../../services/useTenantConfiguration/index.js';
import noop from '../../services/noop/index.js';

import './fonts.css';
import './ssr.css';
import './overrides.css';

export function defaultTheme(mode, { primary, secondary }) {
  return {
    palette: {
      mode,
      primary,
      secondary
    },
    typography: {
      fontFamily: 'Roboto, "Noto Color Emoji", "Helvetica", Arial, sans-serif',
      body1: {
        fontSize: '0.875rem'
      }
    },
    components: {
      MuiTextField: {
        defaultProps: {
          margin: 'dense'
        }
      },
      MuiTooltip: {
        defaultProps: {
          disableInteractive: true,
          slotProps: {
            popper: {
              container: getFullscreenElement
            }
          }
        }
      },
      MuiPopover: {
        defaultProps: {
          container: getFullscreenElement
        }
      },
      MuiModal: {
        defaultProps: {
          container: getFullscreenElement,
          disableEnforceFocus: true
        }
      },
      MuiPopper: {
        defaultProps: {
          container: getFullscreenElement
        }
      },
      MuiAccordion: {
        defaultProps: {
          slotProps: {
            transition: {
              unmountOnExit: true,
              mountOnEnter: true
            }
          }
        }
      },
      MuiAccordionSummary: {
        defaultProps: {
          component: 'div'
        }
      },
      MuiTabs: {
        defaultProps: {
          variant: 'scrollable'
        }
      },
      MuiLink: {
        defaultProps: {
          underline: 'hover'
        }
      },
      MuiSnackbar: {
        defaultProps: {
          autoHideDuration: 5000,
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' }
        }
      }
    }
  };
}

const locales = {
  [LANGUAGE_FR]: [frFR, gridFrFR, pickersFrFR],
  [LANGUAGE_EN]: [enUS, gridEnUS, pickersEnUS]
};

export default function ApplicationContextProvider({ children, colorMode = 'light', language }) {
  const { colorScheme } = useTenantConfiguration(),
        { resolvedLanguage } = useInitializedI18n(language),
        theme = useMemo(mkTheme, [resolvedLanguage, colorMode, colorScheme]),
        [snackbarState, setSnackbarState] = useState({ open: false }),
        value = useMemo(mkProviderValue, [theme]);

  function mkTheme() {
    return createTheme(defaultTheme(colorMode, colorScheme), ...locales[resolvedLanguage]);
  }

  function mkProviderValue() {
    function showMessage(message, action = null, onActionClick = noop, props = undefined) {
      function onClose() {
        setSnackbarState({ open: false });
      }

      setSnackbarState({
        open: true,
        message,
        action,
        onClose,
        onActionClick() {
          onClose();
          onActionClick();
        },
        props
      });
    }

    return {
      theme,
      showMessage
    };
  }

  return (
    <ApplicationContext value={value}>
      <ThemeProvider theme={theme}>
        {children}
        <Snackbar
          open={snackbarState.open}
          message={snackbarState.message}
          onClose={snackbarState.onClose}
          {...snackbarState.props} // Allows overriding props per call to showMessage()
          action={snackbarState.action && (
            <Button color={'secondary'} size={'small'} onClick={snackbarState.onActionClick}>
              {snackbarState.action}
            </Button>
          )}
        />
      </ThemeProvider>
    </ApplicationContext>
  );
}

function getFullscreenElement() {
  return document.fullscreenElement;
}
